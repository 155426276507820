import type { Config } from 'tailwindcss'

// Function to convert a pixel value to rem
// Does not have to include the 'px' unit
// Uses a default font size of 16px
const rem = (value: string | number) => {
  value = String(value).replace('px', '')
  return `${Number(value) / 16}rem`
}

const config: Config = {
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  prefix: 'u-', // prefix for all Tailwind utility classes
  theme: {
    borderRadius: {
      none: '0',
      xs: rem('4px'),
      sm: rem('8px'),
      md: rem('16px'),
      lg: rem('24px'),
      full: rem('1000px'),
    },
    boxShadow: {
      e1: '0 0 2px -1px rgba(0, 0, 0, 0.12), 0 2px 6px -1px rgba(0, 0, 0, 0.16)',
      e2: '0 1px 4px -2px rgba(0, 0, 0, 0.07), 0 2px 12px -2px rgba(0, 0, 0, 0.16)',
      e3: '0 5px 24px -4px rgba(0, 0, 0, 0.20), 0 2px 6px -3px rgba(0, 0, 0, 0.09)',
      e4: '0 8px 12px -5px rgba(0, 0, 0, 0.10), 0 14px 44px -6px rgba(0, 0, 0, 0.22)',
      e5: '0 8px 16px -8px #3f475a66',
      e6: '0px 1.55px 9.27px -1.55px rgba(0, 0, 0, 0.16), 0px 0.77px 3.09px -1.55px rgba(0, 0, 0, 0.7)',
      inner: 'inset',
      none: 'none',
    },
    colors: {
      black: '#000',
      blue: {
        50: '#EEF7FF',
        100: '#D6EAFB',
        200: '#BEDDF8',
        300: '#8FC3F0',
        400: '#469EE9',
        500: '#0075DA',
        600: '#003F6E',
        700: '#002F57',
      },
      cyan: {
        100: '#AEE8F7',
        200: '#00C2F5',
      },
      error: {
        accent: '#AC0040',
        background: '#f9dedc',
      },
      indigo: {
        700: '#181F40',
      },
      gray: {
        50: '#f8f8f8',
        100: '#f1f2f4',
        150: '#eeeef1',
        200: '#e5e6ea',
        300: '#d3d6dc',
        400: '#a8acb9',
        500: '#7d8496',
        550: '#6D7588',
        600: '#45454A',
        650: '#3F475A',
        700: '#232325',
      },
      green: {
        100: '#00B476',
        200: '#008163',
      },
      information: {
        accent: '#003F6E',
        background: '#EEF7FF',
      },
      orange: {
        100: '#F9DFD1',
        200: '#FF7F39',
      },
      purple: {
        100: '#DAD9E8',
        200: '#6460AA',
      },
      success: {
        accent: '#00B476',
        background: '#E1FBEA',
      },
      transparent: 'transparent',
      warning: {
        accent: '#FFB500',
        background: '#FFF6DB',
      },
      white: '#fff',
    },
    fontFamily: {
      icons: 'var(--font-material-symbols-rounded)',
      myriad: 'var(--font-myriad-pro)',
    },
    fontSize: {
      0: '0',
      0.5: rem('2px'),
      1: rem('4px'),
      1.5: rem('6px'),
      2: rem('8px'),
      2.5: rem('10px'),
      3: rem('12px'),
      3.5: rem('14px'),
      4: rem('16px'),
      4.5: rem('18px'),
      5: rem('20px'),
      6: rem('24px'),
      7: rem('28px'),
      8: rem('32px'),
      xs: ['12px', '16px'],
      sm: ['14px', '20px'],
      base: ['16px', '24px'],
      lg: ['20px', '28px'],
      xl: ['24px', '32px'],
    },
    fontWeight: {
      300: '300',
      400: '400',
      600: '600',
      700: '700',
      900: '900',
    },
    screens: {
      xs: rem('375px'),
      xsp: rem('425px'),
      sm: rem('640px'),
      md: rem('768px'),
      lg: rem('1024px'),
      xl: rem('1260px'),
      '2xl': rem('1440px'),
      '3xl': rem('1600px'),
      '4xl': rem('2080px'),
      '5xl': rem('2560px'),
      '6xl': rem('3000px'),
    },
    extend: {
      backgroundImage: {
        'gradient-white-partial':
          'linear-gradient(176deg, rgba(255,255,255,1) 72%, rgba(255,255,255,0) 73%)',
        'gradient-gray-partial':
          'linear-gradient(176deg, rgba(241,241,242,1) 72%, rgba(241,241,242,0) 73%)',
        'gradient-to-r-blue-blue':
          'linear-gradient(131.32deg, #003F6E 0%, #0075DA 100%)',
        'gradient-to-b-blue-blue':
          'linear-gradient(180deg, #003F6E 4%, #0274d7 100%)',
        'gradient-to-t-blue-blue':
          'linear-gradient(0deg, #003F6E 0%, #0274d7 100%)',
        'gradient-to-r-cyan-blue':
          'linear-gradient(131.32deg, #00C2F5 0%, #0075DA 100%)',
        'gradient-to-r-green-green':
          'linear-gradient(104.04deg, #008163 0%, #00B476 100%)',
        'map-marker': "url('/map-marker.svg')",
        'map-marker-blue': "url('/map-marker-blue.svg')",
        'gradient-hero-section':
          'linear-gradient(180deg,#fff 0,#fff 80%,#f6f6f6 100%)',
        'new-to-txu-hero':
          'linear-gradient(90.03deg, rgba(0, 63, 110, 0.75) -8.72%, rgba(0, 117, 218, 0) 215.09%), url("/images/texas-hero-bg.png")',
        'new-to-txu-hero-mobile':
          'linear-gradient(90.03deg, rgba(0, 63, 110, 0.75) -8.72%, rgba(0, 117, 218, 0) 215.09%), url("/images/texas-hero-bg-mobile.png")',
      },
      spacing: {
        13: rem('52px'),
        18: rem('72px'),
      },
      screens: {
        '3xl': rem('1600px'),
        '4xl': rem('1920px'),
        '5xl': rem('2560px'),
      },
    },
  },
  plugins: [],
}
export default config
