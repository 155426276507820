import React, { JSX, useMemo } from 'react'
import { cn } from '@/lib/utils'
import CustomIcon from '@/components/design-system/CustomIcon'

type VariantType = 'priceFocused' | 'newToTXU' | 'highIntent' | 'thoughtfulResearchers' | 'default' | string;

type PropsType = {
  styles: Record<string, string>;
  copies?: {
    label?: {
      mobile: string;
      desktop: string;
    };
  }
  zipLink?: {
    text: string | JSX.Element;
    handleClick?: () => void;
    additionalStyle?: string;
  };
  tabs?: { label: string; value: string }[];
};

export const getZipEntryConfig = (
  type: VariantType,
  handleOnSignInClick: () => void,
  variant?: string,
  phoneNumber?: string,
  formattedPhoneNumber?: string
): PropsType => {
  const PROPS: Record<string, PropsType> = {
    priceFocused: {
      styles: {
        stepContainer: 'u-bg-white u-bg-opacity-10 u-backdrop-blur-3xl u-px-4 u-py-6 u-rounded-md lg:u-py-6 lg:u-px-10',
        formContainer: cn(
          'u-relative u-w-full u-flex u-flex-col u-gap-3 u-h-16 u-items-center',
          'u-flex-row lg:u-gap-4',
          'u-bg-white u-rounded-full u-p-1',
          'u-border u-border-gray-300',
        ),
        input: cn(
          'u-py-3 u-pr-0 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-placeholder-gray-550 u-text-gray-550 u-bg-no-repeat u-bg-[position:1rem_center] u-bg-map-marker-blue u-outline-none lg:u-leading-6',
          variant === 'geo' && 'u-rounded-none md:u-py-4 lg:u-py-[0.75rem]'
        ),
        button: cn(
          'u-h-max hover:u-bg-blue-500 focus:u-bg-blue-500 u-leading-5 u-py-4 lg:u-px-7 lg:u-text-[1.125rem] u-border-none',
          variant === 'geo' && 'md:u-py-4 lg:u-py-[0.688rem]'
        ),
        title: 'u-font-600 u-text-sm md:u-text-base lg:u-text-4.5 u-text-center',
      },
      zipLink: {
        text: 'Already a customer? Sign In',
        handleClick: handleOnSignInClick,
      },
    },
    newToTXU: {
      styles: {
        stepContainer: 'u-bg-gray-150 u-backdrop-blur-3xl u-px-4 u-py-6 !u-rounded-md lg:u-py-6 lg:u-px-10',
        formContainer: cn(
          'u-relative u-w-full u-flex u-flex-col u-gap-3 md:u-h-16 md:u-items-center',
          'md:u-flex-row md:u-gap-4',
          'md:u-bg-white md:u-rounded-full md:u-p-1',
          'md:u-border md:u-border-gray-300'
        ),
        formContent: 'md:u-gap-6 lg:u-gap-y-4',
        input: cn(
          'u-border u-border-gray-300 md:u-border-none',
          'u-py-3 md:u-pr-0 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-placeholder-gray-550 u-text-gray-550 u-bg-no-repeat u-bg-[position:1rem_center] u-bg-map-marker-blue u-outline-none lg:u-leading-6',
          variant === 'geo' && 'u-rounded-none md:u-py-4 lg:u-py-[0.75rem]'
        ),
        button: cn(
          'u-h-max hover:u-bg-blue-500 focus:u-bg-blue-500 u-leading-5 u-py-4 lg:u-px-7 lg:u-text-[1.125rem] u-border-none',
          variant === 'geo' && 'md:u-py-4 lg:u-py-[0.688rem]'
        ),
        title: 'u-font-600 u-text-sm md:u-text-base lg:u-text-4.5 u-text-center u-text-gray-650 lg:u-mb-2',
        legendAdditionalStyle: 'u-text-gray-650',
      },
      zipLink: {
        text: 'Already a customer? Sign In',
        additionalStyle: 'u-text-gray-650',
        handleClick: handleOnSignInClick,
      },
    },
    highIntent: {
      styles: {
        stepContainer: 'u-bg-blue-600 u-backdrop-blur-3xl u-px-4 u-py-6 !u-rounded-md lg:u-pb-6 lg:u-pt-8 lg:u-px-10',
        formContainer: cn(
          'u-relative u-w-full u-flex u-flex-col u-gap-3 lg:u-h-16 lg:u-items-center',
          'lg:u-flex-row lg:u-gap-4',
          'lg:u-bg-white lg:u-rounded-full md:u-p-1',
          'lg:u-border lg:u-border-gray-300'
        ),
        input: cn(
          'u-border u-border-gray-300 lg:u-border-none',
          'u-py-3 md:u-pr-0 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-placeholder-gray-550 u-text-gray-550 u-bg-no-repeat u-bg-[position:1rem_center] u-bg-map-marker-blue u-outline-none lg:u-leading-6',
          variant === 'geo' && 'u-rounded-none md:u-py-4 lg:u-py-[0.75rem]'
        ),
        formContent: 'lg:u-gap-6',
        button: cn(
          'u-h-max hover:u-bg-blue-500 focus:u-bg-blue-500 u-leading-5 u-py-4 lg:u-px-7 lg:u-text-[1.125rem] u-border-none',
          variant === 'geo' && 'md:u-py-4 lg:u-py-[0.688rem]'
        ),
        title: 'u-font-600 u-text-sm md:u-text-base lg:u-text-4.5 u-text-center u-text-white',
        legendAdditionalStyle: 'u-text-white',
      },
      zipLink: {
        text: 'Already a customer? Sign In',
        additionalStyle: 'u-text-white',
        handleClick: handleOnSignInClick,
      },
    },
    thoughtfulResearchers: {
      tabs: [
        { label: 'New to TXU Energy', value: '1' },
        { label: 'Current Customer', value: '2' },
      ],
      copies: {
        label: {
          mobile: 'Enter your ZIP Code to see plans or give us a call',
          desktop: 'Enter your ZIP Code to see plans'
        }
      },
      styles: {
        stepContainer: 'lg:u-rounded-tl-[0] lg:u-pb-6',
        formContainer: cn(
          'u-relative u-w-full u-flex u-flex-col u-gap-3 u-h-16 u-items-center',
          'u-flex-row lg:u-gap-4',
          'u-bg-white u-rounded-full u-p-1',
          'u-border u-border-gray-300'
        ),
        input: cn(
          'u-py-3 u-pr-0 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-placeholder-gray-550 u-text-gray-550 u-bg-no-repeat u-bg-[position:1rem_center] u-bg-map-marker-blue u-outline-none lg:u-leading-6',
          variant === 'geo' && 'u-rounded-none md:u-py-4 lg:u-py-[0.75rem]'
        ),
        button: cn(
          'u-h-max hover:u-bg-blue-500 focus:u-bg-blue-500 u-leading-5 u-py-4 lg:u-px-7 lg:u-text-[1.125rem] u-border-none',
          variant === 'geo' && 'md:u-py-4 lg:u-py-[0.688rem]'
        ),
        title: 'u-font-600 u-text-sm md:u-text-base lg:u-text-4.5 u-text-center',
      },
      zipLink: {
        text: (
          <span className="u-flex u-items-center u-gap-1 md:u-gap-2 u-px-6 u-py-3 md:u-p-0 u-rounded-full u-w-full u-justify-center u-border u-border-[#F3F5F8] md:u-border-none md:u-px-0">
              <span className="u-hidden md:u-block">Or call us at</span>
              <CustomIcon icon="cellPhone" className="u-h-5 u-w-5 u-text-white" />
              <a href={`tel:${phoneNumber}`} className="t-body-base-bold u-leading-5 u-text-white">
                {formattedPhoneNumber}
              </a>
            </span>
        ),
        additionalStyle: 'u-no-underline !t-body-base md:u-px-0',
      },
    },
    default: {
      tabs: [
        { label: 'New to TXU Energy', value: '1' },
        { label: 'Current Customer', value: '2' },
      ],
      styles: {
        stepContainer: 'lg:u-rounded-tl-[0]',
        formContainer: cn(
          'u-relative u-w-full u-flex u-flex-col u-gap-3',
          'lg:u-flex-row lg:u-gap-4',
          variant !== 'geo' && 'lg:u-bg-white lg:u-rounded-full lg:u-p-1',
          variant === 'geo' && 'md:u-bg-transparent md:u-rounded-none md:u-p-0',
          variant === 'geo' && 'md:u-flex-col lg:u-flex-row'
        ),
        input: cn(
          'u-py-3 u-px-4 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-placeholder-gray-600 u-text-gray-700 u-bg-no-repeat u-bg-[position:1rem_center] u-bg-map-marker md:u-outline-none u-leading-[1.625rem] lg:u-leading-6 u-pb-3.5 u-pt-4',
          variant === 'geo' && 'u-rounded-none md:u-py-4 lg:u-py-[0.75rem]'
        ),
        button: cn(
          'hover:u-bg-blue-500 focus:u-bg-blue-500 u-leading-6 u-py-4 lg:u-px-7 lg:u-text-[1.125rem] u-border-none',
          variant === 'geo' && 'md:u-py-4 lg:u-py-[0.688rem]'
        ),
        title: 'u-font-600 u-text-sm md:u-text-base lg:u-text-4.5 u-text-center',
      },
    },
  };

  return PROPS[type] || PROPS.default;
};
