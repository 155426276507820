import React, { JSX } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/design-system/Tabs'
import { Step, Wizard } from '@/components/design-system/Wizard'
import { cn } from '@/lib/utils'
import ExclamationIcon from '../../../../../public/images/icons/exclamation.svg'
import { Button } from '@/components/design-system/Button'
import { ZipEntryComponentProps } from '@/components/design-system/ZipEntry/interface'
import { GetFuseLease } from '@/helpers/fuse'
import { getZipEntryConfig } from '@/components/design-system/ZipEntry/utils/getZipEntryConfig'
import useBreakPoints from '@/hooks/useBreakPoints'

export type ZipEntryVisualType = 'default' | 'priceFocused' | 'newToTXU' | 'highIntent' | 'thoughtfulResearchers'

interface ZipEntryBaseProps extends ZipEntryComponentProps {
  type?: ZipEntryVisualType
}

const ZipEntryBase: React.FC<ZipEntryBaseProps> = (props) => {
  const {
    type = 'default',
    zipCode,
    zipCodeRef,
    handleZipCodeChange,
    handleZipCodeFocus,
    showZipCodeError,
    handleFirstStepSubmit,
    handleSecondStepSubmit,
    handleRadioClick,
    showMovingError,
    showApartmentError,
    handleOnSignInClick,
    variant,
  } = props

  const { active } = useBreakPoints()
  const phoneNumber = GetFuseLease()
  const formattedPhoneNumber = phoneNumber?.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '$1-$2-$3',
  )

  const configuration = getZipEntryConfig(type, handleOnSignInClick, variant, phoneNumber, formattedPhoneNumber)
  const labelPrefix = active === 'mobile' ? 'mobile' : 'desktop'
  return (
    <Tabs defaultValue={'1'}>
      {
        configuration?.tabs && (
          <TabsList>
            {
              configuration?.tabs.map((tab) => (
                <TabsTrigger key={tab.value} value={tab.value}>{tab.label}</TabsTrigger>
              ))
            }
          </TabsList>
        )
      }
      <TabsContent className={configuration.styles?.stepContainer} value="1" data-cy="zip-entry">
        <Wizard>
          {({ nextStep }) => (
            <>
              <Step
                className="u-flex u-flex-col u-items-center u-gap-4"
              >
                <form onSubmit={(e) => handleFirstStepSubmit(e, nextStep)}
                      className={cn('u-flex u-flex-col u-w-full u-gap-4', configuration.styles?.formContent)}
                      noValidate>
                  <h3 className={configuration.styles?.title}>
                    {
                      configuration.copies?.label ? configuration.copies?.label[labelPrefix] : "Enter your ZIP Code to see plans"
                    }
                  </h3>
                  <div className={configuration.styles?.formContainer}>
                    <div className="u-relative u-w-full">
                      <input
                        ref={zipCodeRef}
                        value={zipCode}
                        onChange={handleZipCodeChange}
                        onFocus={handleZipCodeFocus}
                        id="zipCode"
                        name="zipCode"
                        type="tel"
                        pattern="[0-9]{5}"
                        required
                        maxLength={5}
                        placeholder="Enter ZIP Code"
                        autoComplete="off"
                        className={configuration.styles?.input}
                        data-cy="zip-code-input"
                      />
                      <div
                        data-cy="zip-code-error"
                        className={
                          cn(
                            'u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden',
                            'u-absolute u-top-full u-items-center u-gap-2',
                            'before:u-content-[\'\'] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4',
                            showZipCodeError && 'u-flex',
                          )
                        }
                      >
                        <ExclamationIcon /> Please enter a valid ZIP Code
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      className={
                        configuration.styles?.button
                      }
                      type="submit"
                      data-cy="zip-code-button"
                    >
                      Continue
                    </Button>
                  </div>
                  {
                    configuration.zipLink && (
                      <button
                        onClick={configuration.zipLink.handleClick}
                        type="button"
                        className={cn('t-body-sm u-underline u-underline-offset-4 u-w-full md:u-w-auto md:u-px-14', configuration.zipLink.additionalStyle)}
                      >
                        {configuration.zipLink.text}
                      </button>
                    )
                  }
                </form>
              </Step>
              <Step>
                <form
                  onSubmit={(e) => handleSecondStepSubmit(e)}
                  className="u-flex u-flex-col u-gap-3 u-w-full"
                  noValidate
                >
                  <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                    <legend
                      className={cn('u-mb-2 u-text-center lg:u-float-left', configuration.styles?.legendAdditionalStyle)}>Are
                      you moving to a new address?
                    </legend>
                    <div className="u-group u-flex u-gap-3 u-float-right u-relative">
                      <label
                        data-cy="moving-radio-mYesLabel"
                        id="mYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="movey"
                          name="m"
                          required
                          aria-labelledby="mYesLabel"
                          onClick={() => handleRadioClick('Move/Switch', 'Yes', 'User indicates Yes they are moving')}
                          className={
                            cn(
                              'u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full',
                              'checked:before:u-content-[\'\'] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500',
                            )
                          }
                        />
                        Yes
                      </label>

                      <label
                        data-cy="moving-radio-mNoLabel"
                        id="mNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="moven"
                          name="m"
                          required
                          aria-labelledby="mNoLabel"
                          onClick={() => handleRadioClick('Move/Switch', 'No', 'User indicates No they are not moving')}
                          className={
                            cn(
                              'u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full',
                              'checked:before:u-content-[\'\'] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500',
                            )
                          }
                        />
                        No
                      </label>
                      <div
                        data-cy="moving-error"
                        className={
                          cn(
                            'u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden u-w-max',
                            'u-absolute u-z-10 u-top-full u-items-center u-gap-2',
                            'before:u-content-[\'\'] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4',
                            showMovingError && 'u-flex',
                          )
                        }
                      >
                        <ExclamationIcon /> Please select one of these options.
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                    <legend
                      className={cn('u-mb-2 u-text-center lg:u-float-left', configuration?.styles?.legendAdditionalStyle)}>Are
                      you moving to an apartment?
                    </legend>
                    <div className="u-group u-flex u-gap-3 u-float-right u-relative">
                      <label
                        data-cy="apartment-radio-aYesLabel"
                        id="aYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmenty"
                          name="a"
                          required
                          aria-labelledby="aYesLabel"
                          className={
                            cn(
                              'u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full',
                              'checked:before:u-content-[\'\'] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500',
                            )
                          }
                        />
                        Yes
                      </label>

                      <label
                        data-cy="apartment-radio-aNoLabel"
                        id="aNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmentn"
                          name="a"
                          required
                          aria-labelledby="aNoLabel"
                          className={
                            cn(
                              'u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full',
                              'checked:before:u-content-[\'\'] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500',
                            )
                          }
                        />
                        No
                      </label>
                      <div
                        data-cy="apartment-error"
                        className={
                          cn(
                            'u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden u-w-max',
                            'u-absolute u-z-10 u-top-full u-items-center u-gap-2',
                            'before:u-content-[\'\'] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4',
                            showApartmentError && 'u-flex',
                          )
                        }
                      >
                        <ExclamationIcon /> Please select one of these options.
                      </div>
                    </div>
                  </fieldset>
                  <Button
                    variant="primary"
                    type="submit"
                    data-cy="find-plans-button"
                    className="u-mt-3 hover:u-bg-blue-500 focus:u-bg-blue-500"
                  >
                    Find Plans
                  </Button>
                </form>
              </Step>
            </>
          )}
        </Wizard>
      </TabsContent>
      <TabsContent value="2">
        <div className="u-flex u-flex-col u-items-center u-gap-4">
          <h3 className="u-font-600 u-text-base lg:u-text-4.5">Sign in to see plans</h3>
          <Button
            variant="primary"
            size="large"
            onClick={handleOnSignInClick}
            className="u-w-full md:u-w-auto md:u-px-14"
          >
            Sign In
          </Button>
        </div>
      </TabsContent>
    </Tabs>
  )
}

export default ZipEntryBase
