"use client"
import { useRef, useState } from "react"

import { useClickOutside } from "@/hooks/useOnClickOutside"
import { beam } from "@/helpers/beam"
import ZipEntryBase, { ZipEntryVisualType } from '@/components/design-system/ZipEntry/components/ZipEntryBase'

const validateZip = (zip: string) => {
  const zipRegex = new RegExp(/^\d{5}$/)
  return zipRegex.test(zip)
}

type ZipEntryProps = {
  /** callback to be called when the user clicks Find Plans button */
  onFindPlansClick?(URL: string): void
  variant?: "default" | "geo"
  type?: ZipEntryVisualType
}

const ZipEntry = ({ onFindPlansClick, variant, type = "default" }: ZipEntryProps) => {
  const [zipCode, setZipCode] = useState("")
  const [wasZipCodeFocused, setWasZipCodeFocused] = useState(false)
  const zipCodeRef = useRef<HTMLInputElement>(null)
  const [showZipCodeError, setShowZipCodeError] = useState(false)

  const [showMovingError, setShowMovingError] = useState(false)
  const [showApartmentError, setShowApartmentError] = useState(false)
  const [wasRadioClicked, setWasRadioClicked] = useState(false)
  useClickOutside(zipCodeRef, () => setShowZipCodeError(false))

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "")
    setShowZipCodeError(false)
    setZipCode(value)
  }

  const handleZipCodeFocus = () => {
    if (wasZipCodeFocused) {
      return
    }

    setWasZipCodeFocused(true)

    const payload = {
      formContext: {
        formName: "Zip Search Form",
        formType: "Zip Check",
      },
    }
    beam('FormStarted', payload)
    // fire logRocket event
  }

  const handleFirstStepSubmit = (e: React.FormEvent<HTMLFormElement>, callback: () => void) => {
    e.preventDefault()
    const value = new FormData(e.currentTarget).get("zipCode") as string

    if (!validateZip(value)) {
      setShowZipCodeError(true)
      return
    }

    if (validateZip(value)) {
      setShowZipCodeError(false)

      const payload = {
        webElement: {
          location: value,
          position: 'zipEntry',
          elementType: 'cta Button',
          text: 'CONTINUE (Not Existing Customer)'
        },
        outboundUrl: 'shop.txu.com'
      }
      beam('ElementClicked', payload)
      // fire logRocket event

      if (callback && typeof callback === "function") {
        callback()
      }
    }
  }

  const handleRadioClick = (location: string, text: string, actionOutcome: string) => {
    if (wasRadioClicked) {
      return
    }

    setWasRadioClicked(true)

    const payload = {
      webElement: {
        location,
        elementType: 'CTA button',
        text
      },
      actionOutcome
    }
    beam('ElementClicked', payload)
    // fire logRocket event
  }

  const handleSecondStepSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    let URL = `plans/?zipCode=${zipCode}&`

    if (!formData.has("m")) {
      setShowMovingError(true)
      return
    }

    setShowMovingError(false)
    if (!formData.has("a")) {
      setShowApartmentError(true)
      return
    }
    setShowApartmentError(false)

    const queryString = new URLSearchParams(Array.from(formData.entries()).map(([key, value]) => [key, value.toString()])).toString()
    URL += queryString

    const payload = {
      formContext: {
        formType: 'Zip Box',
        formName: 'ZIP/Address Search Form',
        },
      outboundUrl: URL,
      field: [{
        fieldType: 'cta Button',
        fieldName: 'SHOP'
      }]
    }
    beam('FormSubmitted', payload)
    // fire logRocket event

    if (onFindPlansClick && typeof onFindPlansClick === "function") {
      setTimeout(() => {
        onFindPlansClick(URL)
      }, 500)
    }
  }

  const handleOnSignInClick = () => {
    const payload = {
      webElement: {
        location: 'Tabbed Zip Hero',
        position: 'zipEntry',
        elementType: 'cta Button',
        text: 'CONTINUE (Existing Customer)'
      }
    }
    beam('ElementClicked', payload)
    // fire logRocket event

    setTimeout(() => {
      window.location.href = 'https://www.txu.com/signin'
    }, 500)
  }

  return (
    <ZipEntryBase
      type={type}
      variant={variant}
      zipCode={zipCode}
      zipCodeRef={zipCodeRef}
      showZipCodeError={showZipCodeError}
      showMovingError={showMovingError}
      showApartmentError={showApartmentError}
      handleZipCodeChange={handleZipCodeChange}
      handleZipCodeFocus={handleZipCodeFocus}
      handleFirstStepSubmit={handleFirstStepSubmit}
      handleRadioClick={handleRadioClick}
      handleSecondStepSubmit={handleSecondStepSubmit}
      handleOnSignInClick={handleOnSignInClick}
    />
  )
}

export default ZipEntry
