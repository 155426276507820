/**
 * Hook for handling responsive rendering on multiple breakpoints
 */

import { useMediaQuery } from './useMediaQuery'

type Breakpoints = {
  isSmallMobile: boolean
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isWidescreen: boolean
  active: 'mobile' | 'tablet' | 'desktop' | 'widescreen'
  smallerThanDesktop: boolean
}

const useBreakpoints = (): Breakpoints => {
  const isSmallMobile = useMediaQuery('(max-width: 640px)')
  const isMobile = useMediaQuery('(min-width: 641px) and (max-width: 767px)')
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)')
  const isDesktop = useMediaQuery('(min-width: 1024px) and (max-width: 1259px)')
  const isWidescreen = useMediaQuery('(min-width: 1260px)')

  const breakpoints: Breakpoints = {
    isSmallMobile,
    isMobile,
    isTablet,
    isDesktop,
    isWidescreen,
    active: 'tablet', // Default value
    smallerThanDesktop: false, // Default value
  }

  if (isMobile || isSmallMobile) breakpoints.active = 'mobile'
  if (isTablet) breakpoints.active = 'tablet'
  if (isDesktop) breakpoints.active = 'desktop'
  if (isWidescreen) breakpoints.active = 'widescreen'

  // Determine if the viewport is smaller than desktop
  breakpoints.smallerThanDesktop = isSmallMobile || isMobile || isTablet

  return breakpoints
}

export default useBreakpoints
